import request from '@/utils/request'
// 文章相关接口
/**
 * 文章列表
 * @param {*} data
 * @returns
 */
export const getList = data => {
  return request({
    url: '/gmjkylw_web/article/list',
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json'
    },
    data
  })
}

// 文章相关接口
/**
 * 文章详情
 * @param {*} articleId
 * @returns
 */
export const articleDetail = articleId => {
  return request({
    url: `/gmjkylw_web/article/details/${articleId}`,
    method: 'get',
    headers: {
      ['Content-Type']: 'application/json'
    }
  })
}

// 文章相关接口
/**
 * 文章-分类查询
 * @param {*} categoryId
 * @returns
 */
export const queryCategory = categoryId => {
  return request({
    url: `/gmjkylw_web/article/category/${categoryId}`,
    method: 'get',
    headers: {
      ['Content-Type']: 'application/json'
    }
  })
}

// 今日导读
/**
 * 文章-分类查询
 * @param {*} data
 * @returns
 */
export const getTodayRead = data => {
  return request({
    url: `/gmjkylw_web/article/todayRead`,
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json'
    },
    data
  })
}

//
/**
 * 查询机构详情
 * @param {*} id
 * @returns
 */
export const getOrganizationDetail = id => {
  return request({
    url: `/gmjkylw_web/organization/detail/${id}`,
    method: 'get',
    headers: {
      ['Content-Type']: 'application/json'
    }
  })
}
