<template>
  <div>
    <div class="list-page">
      <!--  -->
      <div class="left">
        <div>
          <a-list
            size="large"
            :locale="locale"
            :data-source="listData1"
            class="list-art"
          >
            <a-list-item
              slot="renderItem"
              v-for="item in listData1"
              :key="item.articleId"
              slot-scope="item, index"
              @click="handleDetail(item.articleId)"
            >
              <template #extra>
                <div class="list-item">
                  <!-- <img
                  v-if="item.surfacePlot"
                  class="cover"
                  width="272"
                  alt="logo"
                  :src="item.surfacePlot"
                /> -->
                  <div class="r">
                    <h3 class="title">
                      <span class="cirle"></span>
                      {{ item.title }}
                    </h3>
                    <p class="time" v-if="item.publishTime">
                      {{ item.publishTime }}
                    </p>
                  </div>
                </div>
              </template>
            </a-list-item>
          </a-list>
        </div>
      </div>
      <div class="right">
        <a-list
          size="large"
          :data-source="listData2"
          :locale="locale"
          class="list-art"
        >
          <a-list-item
            slot="renderItem"
            :key="item.articleId"
            v-for="item in listData2"
            slot-scope="item, index"
            @click="handleDetail(item.articleId)"
          >
            <template #extra>
              <div class="list-item">
                <!-- <img
                v-if="item.surfacePlot"
                class="cover"
                width="272"
                alt="logo"
                :src="item.surfacePlot"
              /> -->
                <div class="r">
                  <h3 class="title">
                    <span class="cirle"></span>
                    {{ item.title }}
                  </h3>
                  <p class="time" v-if="item.publishTime">
                    {{ item.publishTime }}
                  </p>
                </div>
              </div>
            </template>
          </a-list-item>
        </a-list>
      </div>
    </div>
    <div class="bottom-pagination" v-if="!$route.query.searchData">
      <a-pagination
        v-model="queryParams.pageNum"
        :total="total"
        show-quick-jumper
        :defaultPageSize="queryParams.pageSize"
        @change="onChangePagination"
      />
    </div>
  </div>
</template>

<script>
import { getList } from '@/api/article'
export default {
  data() {
    return {
      locale: {
        emptyText: ' '
      },
      listData: [],
      listData1: [],
      listData2: [],
      queryParams: {
        pageSize: 30,
        pageNum: 1,
        categoryId: ''
      },
      total: 0
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(val) {
        this.queryParams.categoryId = val.categoryId
        if (val.categoryId) {
          this.getData()
        }
        if (val.searchData) {
          this.listData1 = []
          this.listData2 = []
          const searchData = JSON.parse(val.searchData)
          this.listData = this.$_.chunk(
            searchData,
            Math.ceil(searchData.length / 2)
          )
          this.listData1 = [...this.listData[0]]
          this.listData2 = [...this.listData[1]]
        }
      }
    }
  },
  created() {},
  methods: {
    async getData() {
      const params = {
        ...this.queryParams
      }
      const { data, total } = await getList(params)
      this.total = total
      this.listData = this.$_.chunk(data, Math.ceil(data.length / 2))
      this.listData1 = [...this.listData[0]]
      this.listData2 = [...this.listData[1]]
    },
    handleDetail(articleId) {
      this.$router.push({
        path: '/detail',
        query: {
          articleId
        }
      })
    },
    onChangePagination(pageNum, pageSize) {
      this.queryParams.pageNum = pageNum
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.list-page {
  padding: 0 80px;
  display: flex;
  justify-content: space-around;
  .left {
    // width: 800px;
  }
  .right {
    // width: 740px;
  }
  .list-art {
    // width: 800px;
    .list-item {
      display: flex;
      height: 36px;
      cursor: pointer;
    }
    .cover {
      width: 300px;
      height: 150px;
      object-fit: cover;
      background: #d8d8d8;
    }
    .r {
      width: 800px;
      margin-left: 12px;
      position: relative;
      h3 {
        width: 70%;
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .cirle {
          display: inline-block;
          width: 14px;
          height: 14px;
          background: #212223;
          border-radius: 50%;
          line-height: 33px;
          margin-right: 12px;
        }
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #212223;
      }
      .time {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        white-space: nowrap;
      }
    }
  }
  .ant-list-item {
    padding: 4px 0;
    &:nth-child(1) {
      padding-top: 0;
    }
  }
}
.bottom-pagination {
  text-align: right;
  margin-top: 16px;
  padding-right: 72px;
}
</style>
